<template>
  <div class="tabled">
    <div class="tabled-cell Nunito">
      <!-- <img src="https://i.giphy.com/media/jkZtSdwKOx05BOlapR/giphy.webp"> -->
      <img :src="doggo ? 'https://i.giphy.com/media/jkZtSdwKOx05BOlapR/giphy.webp' : 'https://cdn.gracebot.net/grace/404.svg'" style="height: 250px; padding-bottom: 10px;">
      <h1 v-if="doggo" class="title is-size-3">SHOOT! Page not here! Enjoy Doggos!</h1>
      <h1 v-else class="title is-size-3">Seems this page is not here!</h1>
      <h1 :style="this.$route.query.reason ? 'margin-bottom: 5px;' : ''" class="subtitle is-size-5 has-text-grey-light">Ummmm ok? ¯\_(ツ)_/¯</h1>
      <h1 v-if="this.$route.query.reason" class="subtitle is-size-5 has-text-grey-light">Reason: <code style="background-color: var(--main-color-bg-2);">{{ this.$route.query.reason }}</code></h1>
      {{ $log(this.$route) }}
    </div>
  </div>
</template>
<script>
  export default {
    name: '404',
    components: {},
    data () {
      return {
        doggo: Math.random() <= 0.2
      }
    }
  }
</script>

<style scoped>
.tabled {
  display: table;
  height: 35rem;
  margin: 0 auto;
}
.tabled-cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
</style>
